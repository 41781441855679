var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"content"},[_c('p',{staticClass:"title"},[_vm._v("从源文件快速上手")]),_c('a-divider',{staticStyle:{"margin":"10px 0","background-image":"linear-gradient(to right,  rgb(103, 179, 241),  rgb(103, 179, 241), #f6f6f6, #f6f6f6)"}}),_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v("如果你从GitHub下载了LibCity的源码，你可以使用提供的脚本进行简单的使用：")]),_vm._m(1),_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v("这个例子将会在Foursquare-TKY这个数据集上使用DeepMove模型执行轨迹位置预测任务。并且，该脚本还支持通过命令行来设置参数。下面将给出几个例子作为参考。")]),_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v("如果您想在METR_LA这个数据集上使用DCRNN模型执行交通状态预测任务，只需通过设定相关参数运行脚本：")]),_vm._m(2),_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v("您会得到一些类似下面的输出：")]),_vm._m(3),_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v("正如您所见，原始数据集METR_LA的原子文件会被加载并被分成训练集、验证集和测试集三个部分。我们在训练集上进行参数更新，选择在验证集上效果最佳的模型参数，最后报告其在测试集上的结果。")]),_c('p',{staticStyle:{"text-indent":"2em"}},[_vm._v("开始训练：")]),_vm._m(4),_c('br'),_vm._m(5),_vm._m(6),_vm._m(7),_c('br')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticStyle:{"padding-top":"20px","color":"white"}},[_c('p',{staticStyle:{"margin":"20px 0 30px 130px","font-size":"60px"}},[_vm._v("快速上手")]),_c('p',{staticStyle:{"margin":"0px 0 20px 130px","font-size":"30px"}},[_vm._v(" 该页面会帮助你快速了解LibCity的基本使用. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"code"},[_c('code',{attrs:{"data-lang":"bash"}},[_vm._v("python run_model.py")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"code"},[_c('code',{attrs:{"data-lang":"bash"}},[_vm._v("python run_model.py --task traffic_state_pred --model DCRNN --dataset METR_LA")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"code",staticStyle:{"font-size":"16px"}},[_c('code',{attrs:{"data-lang":"bash"}},[_vm._v(" - INFO - Log directory: ./libcity/log"),_c('br'),_vm._v(" - INFO - Begin pipeline, task=traffic_state_pred, model_name=DCRNN, dataset_name=METR_LA"),_c('br'),_vm._v(" - INFO - Loaded file METR_LA.geo, num_nodes=207"),_c('br'),_vm._v(" - INFO - Loaded file METR_LA.rel, shape=(207, 207)"),_c('br'),_vm._v(" - INFO - Start Calculate the weight by Gauss kernel!"),_c('br'),_vm._v(" - INFO - Loading ./libcity/cache/dataset_cache/point_based_METR_LA_12_12_0.7_0.1_standard_64_True_False_True.npz"),_c('br'),_vm._v(" - INFO - train x: (23974, 12, 207, 2)y: (23974, 12, 207, 2)"),_c('br'),_vm._v(" - INFO - eval x: (3425, 12, 207, 2)y: (3425, 12, 207, 2)"),_c('br'),_vm._v(" - INFO - test x: (6850, 12, 207, 2)y: (6850, 12, 207, 2)"),_c('br'),_vm._v(" - INFO - StandardScaler mean: 54.40592829587626, std: 19.493739270573098"),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"code",staticStyle:{"font-size":"16px"}},[_c('code',{attrs:{"data-lang":"bash"}},[_vm._v(" - INFO - Start training ..."),_c('br'),_vm._v(" - INFO - num_batches:375"),_c('br'),_vm._v(" - INFO - Total trainable parameters 372353"),_c('br'),_vm._v(" - INFO - epoch complete!"),_c('br'),_vm._v(" - INFO - evaluating now!"),_c('br'),_vm._v(" - INFO - Epoch [0/100] (375) train_mae: 3.3774, val_mae: 4.3733, lr: 0.010000, 403.5s"),_c('br'),_vm._v(" - INFO - Saved model at 0"),_c('br'),_vm._v(" - INFO - Val loss decrease from inf to 4.3733, saving to ./libcity/cache/model_cache/DCRNN_METR_LA_epoch0.tar"),_c('br'),_vm._v(" ..."),_c('br'),_vm._v(" - INFO - epoch complete!"),_c('br'),_vm._v(" - INFO - evaluating now!"),_c('br'),_vm._v(" - INFO - Epoch [99/100] (37500) train_mae: 2.8075, val_mae: 2.8501, lr: 0.000001, 401.7s"),_c('br'),_vm._v(" - INFO - Loaded model at 66"),_c('br'),_vm._v(" - INFO - Saved model at ./libcity/cache/model_cache/DCRNN_METR_LA.m"),_c('br'),_vm._v(" - INFO - Start evaluating ..."),_c('br'),_vm._v(" - INFO - Evaluate result is ..."),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("如果您想修改参数，例如"),_c('code',{staticStyle:{"color":"#e83e8c","font-size":"90%"}},[_vm._v("learning_rate")]),_vm._v("，只需根据您的需求增加额外的参数，例如：")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"code"},[_c('code',{attrs:{"data-lang":"bash"}},[_vm._v("python run_model.py --learning_rate 0.001")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("所有支持的参数和更多的细节可见"),_c('a',{attrs:{"target":"_blank","href":"https://bigscity-libcity-docs.readthedocs.io/en/latest/get_started/quick_start.html"}},[_vm._v("文档")]),_vm._v("。")])
}]

export { render, staticRenderFns }